import { FunctionComponent, useMemo, useState } from 'react';

import TimelapseIcon from '@mui/icons-material/Timelapse';
import { IconButton, Tooltip } from '@mui/material';

import { INDICATOR_GREEN_COLOR, INDICATOR_NA_COLOR } from '../../assets/colors';
import { PositionGroup } from '../../types/entities';
import GroupScheduleDialog from '../dialogs/GroupScheduleDialog';

interface Props {
  group: PositionGroup;
  size?: 'small' | undefined;
}

const GroupScheduleButton: FunctionComponent<Props> = ({ group, size }: Props) => {
  const hasActiveSchedule = group.useSmallProfit || group.useTimeOut;
  const sx = size === 'small' ? { fontSize: 20, mx: 0.5 } : {};

  const [showGroupScheduleDialog, setShowGroupScheduleDialog] = useState(false);
  const handleScheduleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowGroupScheduleDialog(true);
  };
  const closeGroupScheduleDialog = () => setShowGroupScheduleDialog(false);

  const groupScheduleBtn = useMemo(() => {
    return (
      <Tooltip title="Group Schedule" placement="top">
        <IconButton
          size="small"
          onClick={handleScheduleBtnClick}
          sx={{ color: hasActiveSchedule ? INDICATOR_GREEN_COLOR : INDICATOR_NA_COLOR }}
        >
          <TimelapseIcon sx={sx} />
        </IconButton>
      </Tooltip>
    );
  }, [hasActiveSchedule]);

  return (
    <>
      {groupScheduleBtn}
      <GroupScheduleDialog isOpen={showGroupScheduleDialog} onClose={closeGroupScheduleDialog} group={group} />
    </>
  );
};

export default GroupScheduleButton;
