import { useState } from 'react';

import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { fetchContractDetails, fetchUnderlyingContractDetails } from '../../api';
import { ContractDetails, Position } from '../../types/entities';

export const useContractDetailsQuery = () => {
  const [loading, setLoading] = useState(false);

  const fetchContractDetailsAsync = async (position: Position, underlying = false) => {
    setLoading(true);
    let details: ContractDetails | undefined;
    try {
      const response = underlying ? await fetchUnderlyingContractDetails(position) : await fetchContractDetails(position);
      details = response.data;
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    }
    return details;
  };

  return { fetchContractDetailsAsync, loading };
};
