import { FunctionComponent } from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR, TABLE_BORDER_COLOR } from '../../assets/colors';
import ShowGraphIconButton from '../../components/ui/ShowGraphIconButton';
import { useAppSelector } from '../../store/hooks';
import { Position } from '../../types/entities';
import { formatNum, setThousenSeparator } from '../../utils/currency-utils';

interface Props {
  conId: number;
}

const ExistingPositionTable: FunctionComponent<Props> = ({ conId }: Props) => {
  const { positions } = useAppSelector((gs) => gs.positionState);
  const position = positions.find((x) => x.conId === Number(conId));

  if (!position) {
    return (
      <Box sx={{ m: 2 }}>
        <small>Position not found..</small>
      </Box>
    );
  }

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Sec Type</TableCell>
        <TableCell>Parent</TableCell>
        <TableCell>Local</TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="center">Right</TableCell>
        <TableCell align="right">Entry Price</TableCell>
        <TableCell align="right">Opt Price</TableCell>
        <TableCell align="right">Premium</TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );

  const stopPropagation = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.stopPropagation();
  };

  const orderRows = [position].map((pos: Position, i) => {
    const isOption = pos.secType === 'OPT' || pos.secType === 'FOP';
    const ep = (isOption ? pos?.underEntryPrice : pos?.entryPrice) ?? 0;
    const entryPrice = ep > 0 ? `$${formatNum(ep)}` : '-';

    const marketPrice = pos.marketPrice != 0 ? `$${formatNum(pos.marketPrice)}` : '-';
    const totalPremium = pos.premium * pos.size;
    const totalPremiumFormatted = totalPremium != 0 ? `$${setThousenSeparator(totalPremium.toFixed(0))}` : '-';
    const premiumColor = pos.premium == 0 ? 'Inherit' : pos.orderAction === 'BUY' ? INDICATOR_RED_COLOR : INDICATOR_GREEN_COLOR;

    return (
      <TableRow key={`${pos.conId}-${i}`} sx={{ whiteSpace: 'nowrap' }}>
        <TableCell>{pos.secType}</TableCell>
        <TableCell>{pos.underSymbol}</TableCell>
        <TableCell>{pos.localSymbol}</TableCell>
        <TableCell align="center">{pos.size}</TableCell>
        <TableCell>{pos.orderType}</TableCell>
        <TableCell>{pos.orderAction}</TableCell>
        <TableCell align="center">{pos.right || '-'}</TableCell>
        <TableCell align="right">{entryPrice}</TableCell>
        <TableCell align="right" sx={{ borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          {marketPrice}
        </TableCell>
        <TableCell align="right" sx={{ color: premiumColor }}>
          {totalPremiumFormatted}
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5 }} onClick={stopPropagation}>
          <ShowGraphIconButton position={pos} />
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer>
        <Table size="small">
          {tableHeader}
          <TableBody>{orderRows}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExistingPositionTable;
