import { FunctionComponent, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import { GOLD_COLOR, INDICATOR_INFO_COLOR, INDICATOR_RED_COLOR, SIGNAL_GREEN_COLOR } from '../../../assets/colors';
import { CandleStickBar, ChartTrendLine, OrderAction } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { formatNum } from '../../../utils/currency-utils';

import { addSeries, GraphData, LineWidth, StrokeType } from './utils';

interface Props {
  bars: CandleStickBar[];
  barSize: BarSize;
  title?: string;
  entry?: number;
  entryDt?: Date | string | undefined;
  strike?: number;
  limit?: number;
  stop?: number;
  action?: OrderAction;
  onChartMouseClick?: (yAxis: number, bar: CandleStickBar) => void;
  trendLines?: ChartTrendLine[] | undefined;
}

const BarChart: FunctionComponent<Props> = ({
  title = 'Candlestick',
  bars,
  barSize,
  entry,
  entryDt,
  strike,
  limit,
  stop,
  onChartMouseClick,
  trendLines = []
}) => {
  let currentYAxisValue = 0;
  const yAxisFormatter = (value: number) => {
    currentYAxisValue = value; // hack to get the current y-axis value at mouse click
    return `${formatNum(value)}`;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBarClick = (event: any, chartContext: any, opts: any) => {
    const index = opts.seriesIndex !== -1 ? opts.seriesIndex : 0;
    const bar: CandleStickBar = opts.config.series[index].data[opts.dataPointIndex];
    if (opts.dataPointIndex !== -1 && onChartMouseClick) {
      onChartMouseClick(currentYAxisValue, bar);
    }
  };

  const barData = useMemo(() => {
    if (!bars || bars.length === 0) {
      return [];
    }
    return bars
      .filter((bar) => !!bar.x)
      .map((bar) => ({
        x: new Date(bar.x),
        y: bar.y
      }));
  }, [bars, bars?.length]);

  const graphData: GraphData = {
    data: barData,
    series: [],
    strokes: [],
    lineWidth: []
  };

  addSeries(graphData, 'candlestick', 'Bars', 'gray', StrokeType.Solid, LineWidth.Narrow);

  if (trendLines.length > 0 && barData.length > 0) {
    for (let i = 0; i < trendLines.length; i++) {
      const trendLineObj = trendLines[i];
      if (trendLineObj.hidden === true) {
        continue;
      }
      if (trendLineObj.specificOnly && trendLineObj.specificBarSize !== barSize) {
        continue;
      }

      addSeries(graphData, 'horizontal-line', `TL${i + 1}`, trendLineObj.color, StrokeType.Solid, LineWidth.Narrow, trendLineObj.price);
    }
  }

  if (entry && entryDt && barData.length > 0) {
    addSeries(graphData, 'horizontal-line', 'Entry Price', INDICATOR_INFO_COLOR, StrokeType.Dotted, LineWidth.Narrow, entry);
    addSeries(
      graphData,
      'vertical-line',
      'Entry Time',
      INDICATOR_INFO_COLOR,
      StrokeType.Dotted,
      LineWidth.Narrow,
      undefined,
      new Date(entryDt)
    );
  }

  if (strike && barData.length > 0) {
    addSeries(graphData, 'horizontal-line', 'Strike', GOLD_COLOR, StrokeType.Dashed, LineWidth.Standard, strike);
  }

  if (limit && barData.length > 0) {
    addSeries(graphData, 'horizontal-line', 'Limit', SIGNAL_GREEN_COLOR, StrokeType.Dashed, LineWidth.Fat, limit);
  }

  if (stop && barData.length > 0) {
    addSeries(graphData, 'horizontal-line', 'Stop', INDICATOR_RED_COLOR, StrokeType.Dashed, LineWidth.Fat, stop);
  }

  const options: ApexOptions = {
    theme: {
      mode: 'dark'
    },
    chart: {
      background: 'inherit',
      events: {
        click: handleBarClick
      }
    },
    title: {
      text: title,
      align: 'left'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true
      }
    },
    stroke: {
      width: graphData.lineWidth,
      dashArray: graphData.strokes
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter: yAxisFormatter
      }
    }
  };

  return (
    <Box>
      <Typography component="div">
        <ReactApexChart options={options} series={graphData.series} />
      </Typography>
    </Box>
  );
};

export default BarChart;
