import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import { useAppDispatch } from '../../../store/hooks';
import { updatePositionGroupAsync } from '../../../store/position-group/service';
import { PositionGroup } from '../../../types/entities';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import GroupLogTable from './GroupLogTable';
import GroupScheduleForm from './GroupScheduleForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  group: PositionGroup;
}

const GroupScheduleDialog: FunctionComponent<Props> = ({ isOpen, onClose, group }: Props) => {
  const [groupForm, setGroupForm] = useState(group);

  useEffect(() => {
    const copy = {
      ...group,
      logs: [...(group.logs ?? [])]
    };
    setGroupForm(copy);
  }, [group]);

  const dispatch = useAppDispatch();

  const handleSaveBtnClick = () => {
    dispatch(updatePositionGroupAsync(groupForm));
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="md" onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Group Schedule</Typography>
        <Typography variant="caption" component="div" sx={{ mt: 0.5 }}>
          {group.title}
        </Typography>
        <DialogCloseIconButton onClose={onClose} />
      </Box>

      <DialogContent sx={{ p: 0, maxHeight: '50vh' }}>
        <Box sx={{ my: 2 }}>
          <GroupLogTable logs={group.logs ?? []} />
        </Box>

        <GroupScheduleForm p={2} group={groupForm} onChange={setGroupForm} />

        <Divider />
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="contained" color="primary" onClick={handleSaveBtnClick}>
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupScheduleDialog;
