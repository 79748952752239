import { FunctionComponent, useEffect, useState } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';

import { getFundamentalLinkBySymbol } from '../../../store/fundamental-link/selectors';
import { getFundamentalLinksAsync } from '../../../store/fundamental-link/service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { FundamentalLink, Security } from '../../../types/entities';
import { FundamentalLinkType } from '../../../types/enums';

interface Props {
  security?: Security | undefined;
  onEditBtnClick?: (link: FundamentalLink | undefined) => void;
  readOnly?: boolean;
}

const NewsLinkTable: FunctionComponent<Props> = ({ onEditBtnClick, security, readOnly = false }: Props) => {
  const symbol = security?.parentSymbol || security?.symbol;

  const fundamentalLinkState = useAppSelector((gs) => gs.fundamentalLinkState);
  const { loading, loaded, links } = getFundamentalLinkBySymbol(fundamentalLinkState, symbol || '*');

  const dispatch = useAppDispatch();

  const [initialLoaded, setInitialLoaded] = useState(false);
  useEffect(() => {
    setInitialLoaded(true);
  }, []);

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getFundamentalLinksAsync(symbol));
    }
  }, [loading, loaded, symbol]);

  const handleLinkBtnClick = (link: FundamentalLink) => {
    window.open(link.url);
  };

  const handleEditBtnClick = (event: React.MouseEvent<HTMLElement>, link: FundamentalLink) => {
    event.stopPropagation();
    if (onEditBtnClick) {
      onEditBtnClick(link);
    }
  };

  if (!initialLoaded && loading) {
    return <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={50} />;
  }

  const sortByOrderAndTitle = (a: FundamentalLink, b: FundamentalLink) => {
    if (a.sortOrder > b.sortOrder) return 1;
    if (a.sortOrder < b.sortOrder) return -1;
    return a.title > b.title ? 1 : -1;
  };

  const tableBody = (
    <TableBody>
      {links
        .slice()
        .sort(sortByOrderAndTitle)
        .map((x) => (
          <TableRow key={x.id} sx={{ cursor: 'pointer' }} onClick={() => handleLinkBtnClick(x)}>
            <TableCell sx={{ width: '100%' }}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => (x.type === FundamentalLinkType.General ? theme.palette.success.light : theme.palette.info.light)
                  }}
                >
                  {x.title}
                </Typography>
                <Typography variant="caption" sx={{ fontStyle: 'italic', color: '#ffg' }}>
                  {x.tooltip}
                </Typography>
              </Box>
            </TableCell>
            <TableCell align="right" sx={{ width: '50px', px: 2, verticalAlign: 'center' }}>
              {!readOnly && (
                <Tooltip title="Edit Link" placement="left-start">
                  <IconButton sx={{ mt: 0.5 }} size="small" color="primary" onClick={(e) => handleEditBtnClick(e, x)}>
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );

  const table = (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        {tableBody}
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      {links.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ px: 2 }} fontSize="smaller" component="div">
            No news-links exists..
          </Typography>
        </Box>
      ) : (
        table
      )}
    </Box>
  );
};

export default NewsLinkTable;
