import { FunctionComponent, useMemo, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, CircularProgress, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';

import { TABLE_BORDER_COLOR } from '../../../assets/colors';
import { useMarketDataQuery } from '../../../components/hooks/useMarketDataQuery';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { placeOptionEntryOrderAsync as placeOptionEntryOrderAsync, placeOptionExitOrderAsync } from '../../../store/order-option/service';
import { removeSummaryOrder, updateSummaryOrder } from '../../../store/order-summary/reducer';
import { placeUnderlyingEntryOrderAsync, placeUnderlyingExitOrderAsync } from '../../../store/order-underlying/service';
import { ContractMarketData, OpenOrderReport, Position } from '../../../types/entities';
import { formatNum, setThousenSeparator } from '../../../utils/currency-utils';
import { Logger } from '../../../utils/Logger';

import EditOrderDialog from './EditOrderDialog';
import { getPremiumColor } from './utils';

interface Props {
  order: Position;
  groupId: string | undefined;
  onRowClick: (pos: Position | undefined) => void;
  orderSelected: Position | undefined;
  openOrders: OpenOrderReport[] | undefined;
}

const OrderRow: FunctionComponent<Props> = ({ order, groupId, onRowClick, orderSelected, openOrders = [] }: Props) => {
  const dispatch = useAppDispatch();
  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);
  const getMarketDataAsync = useMarketDataQuery();
  const entryPrice = order.entryPrice != 0 ? `$${formatNum(order.entryPrice)}` : '-';
  const totalPremium = order.premium * order.orderSize;
  const premiumColor = getPremiumColor(order);
  const totalPremiumFormatted = totalPremium != 0 ? `$${setThousenSeparator(totalPremium.toFixed(0))}` : '-';
  const isSumbitted = openOrders.filter((x) => x.type === order.orderType).length > 0;

  const marketPrice = useMemo(() => {
    if (order.secType === 'FUT' && order.marketPrice > 0 && !!order.multiplier) {
      const sum = order.marketPrice * Number(order.multiplier);
      return `$${setThousenSeparator(sum.toFixed(0))}`;
    } else if (order.secType === 'STK') {
      const sum = order.marketPrice * 100;
      return `$${setThousenSeparator(sum.toFixed(0))}`;
    }
    return '-';
  }, [order]);

  const [placeOrderBtnClicked, setPlaceOrderBtnClicked] = useState(false);
  const [refreshingMarketData, setRefreshingMarketData] = useState(false);
  const [showPositionEditDialog, setShowPositionEditDialog] = useState(false);
  const closePositionEditDialog = () => setShowPositionEditDialog(false);

  const onOrderChanged = (order: Position) => {
    dispatch(updateSummaryOrder({ symbol: order.symbol, order }));
  };

  const onOrderRemoved = (order: Position) => {
    dispatch(removeSummaryOrder({ symbol: order.symbol, conId: order.conId }));
  };

  const getMarketPrice = (marketData: ContractMarketData) => {
    if (marketData.lastPrice) {
      return marketData.lastPrice;
    }
    const sum = marketData.askPrice + marketData.bidPrice;
    if (sum !== 0) {
      return sum / 2;
    }
    return undefined;
  };

  const refreshMarketData = async (p: Position) => {
    const pos = { ...p };
    setRefreshingMarketData(true);
    try {
      const marketData = await getMarketDataAsync(pos, useFrozen);
      const marketPrice = getMarketPrice(marketData);
      if (marketPrice) {
        if (pos.secType === 'FOP' || pos.secType === 'OPT') {
          pos.marketPrice = marketPrice;
          if (pos.multiplier) {
            pos.premium = marketPrice * Number(pos.multiplier);
          }
        } else {
          pos.marketPrice = marketPrice;
          if (pos.orderType === 'MKT') {
            pos.entryPrice = marketPrice;
          }
        }
        onOrderChanged(pos);
      }
    } catch (error) {
      Logger.log(error);
    } finally {
      setRefreshingMarketData(false);
    }
  };

  const handleRowMouseClick = (pos: Position) => {
    const p = pos.conId !== orderSelected?.conId ? pos : undefined;
    onRowClick(p);
  };

  const handleRefreshBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await refreshMarketData(order);
  };

  const handleEditBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowPositionEditDialog(true);
  };

  const handlePlaceOrderBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPlaceOrderBtnClicked(true);
    const pos = {
      ...order,
      groupId: groupId || ''
    };
    const isExitOrder = pos.size != 0; // set by portfolio
    const isOptionOrder = pos.secType === 'FOP' || pos.secType === 'OPT';
    if (isOptionOrder) {
      if (isExitOrder) {
        dispatch(placeOptionExitOrderAsync(pos));
      } else {
        dispatch(placeOptionEntryOrderAsync(pos));
      }
    } else {
      if (isExitOrder) {
        dispatch(placeUnderlyingExitOrderAsync(pos));
      } else {
        dispatch(placeUnderlyingEntryOrderAsync(pos));
      }
    }
  };

  const handleRemoveOrderBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(removeSummaryOrder({ symbol: order.symbol, conId: order.conId }));
  };

  return (
    <>
      <TableRow
        selected={order.conId === orderSelected?.conId}
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => handleRowMouseClick(order)}
      >
        <TableCell>{order.secType}</TableCell>
        <TableCell>{order.underSymbol}</TableCell>
        <TableCell>{order.localSymbol}</TableCell>
        <TableCell align="center">{order.orderSize}</TableCell>
        <TableCell>{order.orderType}</TableCell>
        <TableCell>{order.orderAction}</TableCell>
        <TableCell align="center">{order.right || '-'}</TableCell>
        <TableCell align="right">{entryPrice}</TableCell>
        <TableCell align="right">{marketPrice}</TableCell>
        <TableCell align="right" sx={{ color: premiumColor }}>
          {totalPremiumFormatted}
        </TableCell>
        <TableCell align="right" sx={{ width: '50px', px: 1 }}>
          <Tooltip title="Refresh Price and Premium">
            <IconButton size="small" onClick={handleRefreshBtnClick}>
              <RefreshIcon sx={{ fontSize: 20 }} />
              {refreshingMarketData && (
                <CircularProgress
                  size={30}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          <span>
            <Button size="small" variant="outlined" onClick={handleEditBtnClick}>
              Edit
            </Button>
          </span>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1 }}>
          <span>
            <Button
              size="small"
              color="success"
              variant="contained"
              onClick={handlePlaceOrderBtnClick}
              disabled={placeOrderBtnClicked || isSumbitted}
            >
              Place
            </Button>
          </span>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          <span>
            <Button size="small" color="error" variant="contained" onClick={handleRemoveOrderBtnClick}>
              Remove
            </Button>
          </span>
        </TableCell>
      </TableRow>
      <EditOrderDialog
        isOpen={showPositionEditDialog}
        onClose={closePositionEditDialog}
        position={order}
        onChange={onOrderChanged}
        onDelete={onOrderRemoved}
      />
    </>
  );
};

export default OrderRow;
