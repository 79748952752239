import { FunctionComponent, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createPositionGroupAsync, removePositionGroupAsync, updatePositionGroupAsync } from '../../store/position-group/service';
import { PositionGroup } from '../../types/entities';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  group?: PositionGroup;
}

const PositionGroupDialog: FunctionComponent<Props> = ({ isOpen, onClose, group }: Props) => {
  const { loaded, error } = useAppSelector((gs) => gs.positionGroupState);
  const [actionPressed, setActionPressed] = useState(false);
  const isNewGroup = !group;
  const now = new Date();

  const groupTemplate: PositionGroup = {
    title: '',
    created: now,
    unrealizedPnl: 0,
    realizedPnl: 0,
    sortOrder: 0,
    inactive: false,
    expanded: true,
    useSmallProfit: false,
    smallProfitDelayHours: 0,
    smallProfit: 0,
    useTimeOut: false,
    timeOutDelayHours: 0,
    logs: []
  };

  const [groupForm, setGroupForm] = useState<PositionGroup>(group || groupTemplate);

  useEffect(() => {
    if (isOpen && group) {
      setGroupForm(group);
    }
  }, [group, isOpen]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateGroupForm = (field: keyof PositionGroup, value: any) => {
    const copy = {
      ...groupForm,
      [field]: value
    } as PositionGroup;
    setGroupForm(copy);
  };

  const reset = () => setGroupForm(groupTemplate);

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
    reset();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  }, [actionPressed, loaded, error]);

  const dispatch = useAppDispatch();

  const handleSaveGroup = () => {
    setActionPressed(true);
    if (isNewGroup) {
      dispatch(createPositionGroupAsync(groupForm));
    } else {
      dispatch(updatePositionGroupAsync(groupForm));
    }
  };

  const handleRemoveGroup = () => {
    setActionPressed(true);
    if (isNewGroup) {
      handleCloseDialog();
    } else {
      dispatch(removePositionGroupAsync(groupForm));
    }
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box>
          <Typography variant="h6">Position Group</Typography>
          <Typography>{groupForm.title ? <span>{groupForm.title}</span> : <i>Title Placeholder</i>}</Typography>
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          name="title"
          size="small"
          label="Title"
          variant="outlined"
          type="text"
          value={groupForm.title || ''}
          onChange={(e) => updateGroupForm('title', e.target.value)}
        />
        <TextField
          name="description"
          size="small"
          label="Description"
          variant="outlined"
          type="text"
          multiline
          rows={2}
          value={groupForm.description || ''}
          onChange={(e) => updateGroupForm('description', e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {!isNewGroup && (
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, justifyContent: 'flex-start' }}>
            <Button variant="outlined" color="error" onClick={handleRemoveGroup}>
              Remove
            </Button>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSaveGroup}>
            Save
          </Button>
          <Button onClick={handleCloseDialog} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PositionGroupDialog;
