import { FunctionComponent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  tabsClasses,
  Typography
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { updatePositionAsync } from '../../../store/position/service';
import { Position } from '../../../types/entities';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import ClosePositionPanel from './ClosePositionPanel';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const EditPositionDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const { loaded, error } = useAppSelector((gs) => gs.positionState);
  const { groups } = useAppSelector((gs) => gs.positionGroupState);
  const [actionPressed, setActionPressed] = useState(false);

  const [groupId, setGroupId] = useState<string>(position.groupId || '');

  useEffect(() => {
    setGroupId(position.groupId || '');
  }, [isOpen]);

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  });

  const dispatch = useAppDispatch();

  const handleSave = () => {
    setActionPressed(false);
    const pos = {
      ...position,
      groupId
    };
    dispatch(updatePositionAsync(pos));
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box>
          <Typography variant="h6">Edit Position</Typography>
          <Typography>
            {position.underSymbol} - {position.localSymbol}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogCloseIconButton onClose={handleCloseDialog} />

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          mx: 2,
          mb: 1,
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
      >
        <Tab label="Settings" />
        <Tab label="Close" />
      </Tabs>

      {selectedTab === 0 && (
        <Box>
          <DialogContent sx={{ p: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="month-label" size="small">
                Position Group
              </InputLabel>
              <Select
                name="group-label"
                labelId="group-label"
                value={groupId ?? ''}
                label="Position Group"
                onChange={(e) => setGroupId(e.target.value)}
                size="small"
              >
                <MenuItem key="" value="">
                  Velg..
                </MenuItem>
                {}
                {groups.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ p: 2, flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: 1 }}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button onClick={handleCloseDialog} variant="outlined">
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Box>
      )}

      {selectedTab === 1 && <ClosePositionPanel onClose={onClose} position={position} />}
    </Dialog>
  );
};

export default EditPositionDialog;
