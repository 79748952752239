import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Container, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ContractDetailsPicker from '../../components/panels/ContractDetailsPicker';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import SimpleWizardNav from '../../components/wizard/SimpleWizardNav';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedContractDetails } from '../../store/user-settings/reducer';
import { ContractDetails, OrderType } from '../../types/entities';

import OptionEntryOrderPanel from './OptionEntryOrderPanel';
import OptionExitOrderPanel from './OptionExitOrderPanel';
import OrderTabList from './OrderTabList';
import OrderUnderlyingPanel from './OrderUnderlyingPanel';

const OrderPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const posConIdParam = searchParams.get('conId') ?? 0;
  const posConId = Number(posConIdParam);
  const selectedTab = searchParams.get('tab') ?? '';
  const orderTypeParam = searchParams.get('type');
  const orderType = orderTypeParam ? (orderTypeParam.toUpperCase() as OrderType) : 'MKT';

  const { selectedContractDetails } = useAppSelector((gs) => gs.userSettingsState);

  const handleSelectionChanged = (details: ContractDetails | undefined) => {
    dispatch(setSelectedContractDetails(details));
  };

  const handleGoToOrderSummaryPage = () => {
    navigate(`/order-summary/${selectedContractDetails?.contract.symbol}`);
  };

  const [wizardStep, setWizardStep] = useState(0);

  useEffect(() => {
    if (selectedContractDetails) {
      setWizardStep(1);
    }
  }, []);

  const selectContractStep = (
    <>
      <ContractDetailsPicker selected={selectedContractDetails} onSelectionChanged={handleSelectionChanged} />
      <SimpleWizardNav activeStep={0} steps={3} next={() => setWizardStep(1)} disableNext={!selectedContractDetails} />
    </>
  );

  const addOrderStep = useMemo(() => {
    return selectedContractDetails ? (
      <>
        {selectedTab === 'underlying' && <OrderUnderlyingPanel underlying={selectedContractDetails} orderType={orderType} />}
        {selectedTab !== 'underlying' && orderType === 'MKT' && <OptionEntryOrderPanel underlying={selectedContractDetails} />}
        {selectedTab !== 'underlying' && orderType !== 'MKT' && (
          <OptionExitOrderPanel conId={posConId} underlying={selectedContractDetails} orderType={orderType} />
        )}
        <SimpleWizardNav activeStep={1} steps={3} previous={() => setWizardStep(0)} next={handleGoToOrderSummaryPage} />
      </>
    ) : undefined;
  }, [selectedTab, selectedContractDetails]);

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Place Order
        </Typography>
      </Container>

      <OrderTabList selectedTab={selectedTab} orderType={orderType} />
      <ResponsiveContainer>
        {wizardStep === 0 && selectContractStep}
        {wizardStep === 1 && addOrderStep}
      </ResponsiveContainer>
    </>
  );
};

export default OrderPage;
